<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-23 19:58:17
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 12:08:43
-->
<template>
  <div class="Home">
    <!--  列表 -->
    <div class="group-list">
      <div class="group-item" v-for="(i, index) in data" :key="index">
        <div class="group">
          <div class="group-name">
            <img class="icon" :src="i.usageCategoryIcon" />
            <span class="name">{{ i.usageCategoryName }}</span>
          </div>
          <div class="content">
            <div class="remark">{{ i.usageCategoryDescribe }}</div>
            <div class="art-list">
              <ul>
                <li v-for="item in i.articles" :key="item.id" @click.stop="handleToArt(item, i.usageCategoryId)">
                  <div class="artDesc">{{ item.articleName || $t("Detail.noName") }}</div>
                  <img class="left-icon" src="../../assets/Notice-icon.png" alt />
                </li>
              </ul>
            </div>
            <div class="art-num" @click="enterCorrespondpage(i)">
              <div>{{ $t("Home.total") + i.articleCount + $t("Home.nums") + " , " + $t("Home.more") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",

  data() {
    return {
      expand: true,
      data: {},
    };
  },
  computed: {
    language() {
      return this.$store.state.defaultlanguage;
    },
    usageManageId() {
      return this.$store.state.wikiAppId;
    },
    usageStyleId: function () {
      return this.$store.state.globalConfig.usageStyleId;
    },
  },
  watch: {
    language() {
      this.usageCategoryList();
    },
  },
  created() {},
  mounted() {
    this.usageCategoryList();
  },
  methods: {
    enterCorrespondpage(data) {
      if (this.usageStyleId == 1) {
        this.$router.push({
          name: "Collections",
          params: this.$route.params,
          query: { id: data.id },
        });
      } else {
        if (data.articles && data.articles.length > 0) {
          let articleId = data.articles[0].articleId;
          this.$router.push({
            name: "Detail",
            params: this.$route.params,
            query: {
              articleId,
              usageCategoryId: data.usageCategoryId,
            },
          });
        } else {
          this.$message.warning("该分类下未关联文章");
        }
      }
    },
    handleToArt(item, usageCategoryId) {
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: { articleId: item.articleId, usageCategoryId },
      });
    },
    usageCategoryList() {
      let parmas = {
        usageManageId: this.usageManageId,
        language: this.language,
      };
      this.$api.usageCategoryList(parmas).then((res) => {
        if (res.code == 200) {
          this.data = res.data.filter((item) => {
            return item.articleCount != 0;
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
            duration: 1000,
            showClose: true,
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.group-list {
  padding: 0.5128rem 0.5128rem 0;
  min-height: 59vh;
  .group {
    background: #ffffff;
    box-shadow: 0 0.0513rem 0.6154rem 0 rgba(0, 77, 193, 0.08);
    border-radius: 0.2051rem;
    padding: 0.5128rem 0.4103rem;
    margin-top: 0.6154rem;
    .group-name {
      display: flex;
      align-items: center;
      margin-bottom: 0.3077rem;
      .icon {
        width: 0.6667rem;
        height: 0.6667rem;
        margin-right: 0.3077rem;
      }
      .name {
        font-size: 0.4359rem;
        font-family: PingFangSC-Regular;
        font-size: 0.4872rem;
        color: #000000;
      }
    }

    .content {
      .remark {
        width: 7.6923rem;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 400;
      }
      .art-list {
        margin: 0.3077rem 0;
        ul {
          padding: 0;
          margin: 0;
        }
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          list-style: none;
          height: 40px;
          .artDesc {
            font-family: PingFangSC-Medium;
            font-weight: 500;
            max-width: 7.667rem;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.8);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:active {
              color: var(--theme-color);
            }
          }
        }
        .left-icon {
          width: 18px;
          height: 18px;
        }
      }
      .art-num {
        height: 18px;
        display: flex;
        align-items: center;
        font-size: 0.3333rem;
        color: rgba(0, 0, 0, 0.4);
        letter-spacing: -0.0044rem;
        img {
          width: 18px;
          height: 18px;
        }
        span {
          height: 18px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
